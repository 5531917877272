import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SocialRegister from './SocialRegister';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { socialSignup, userSignup, userSignupStatus } from '../../redux/action/authAction';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInputComponent from '../../components/phone-input/PhoneInputComponent'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { PulseLoader } from 'react-spinners';

function SignUpForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/;

  const location = useLocation();
  // const auth = useSelector(state => state?.user?.userData?.isAuth)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isLoader = useSelector((state) => state?.auth?.isLoader);
  const isEmailVerified = useSelector((state) => state?.auth?.userData?.isEmailVerified);
  const signupStatus = useSelector((state) => state?.auth?.userSignupStatus);
  
  const queryParams = new URLSearchParams(location.search);
  const referralByCode = queryParams.get('referralCode');
  console.log(referralByCode, "referralCode")

  const createUserSchema = Yup.object({
    name: Yup.string()
      .min(3, 'Name must be 3 characters or long.')
      .max(50, 'Name must be 50 characters or long.')
      .required('Name is required.'),

    email: Yup.string()
      .email('Please enter a valid email.')
      .required('Email is required.'),

    phoneNumber: Yup.string()
      .matches(/^\d*$/, 'Phone number must be numeric')
      .nullable(true),

    password: Yup.string()
      .matches(passwordRegex, 'Password must be at least 8 characters long and include at least 1 digit, 1 uppercase, 1 lowercase character and 1 special character.')
      .required('Password is required.'),

    confirmPassword: Yup.string()
      .matches(passwordRegex, 'Password must be at least 8 characters long and include at least 1 digit, 1 uppercase, 1 lowercase character and 1 special character.')
      .required('Confirm password is required.')
      .oneOf([Yup.ref("password")], "The passwords do not match."),
  })

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    document.title = 'Cryptordle - Signup';
    if (signupStatus) {
      navigate('/email-verify-otp');
      dispatch(userSignupStatus(false))
    }
  }, [signupStatus, navigate, dispatch])

  return (
    <div className='pb-4'>
      <Formik
        enableReinitialize
        initialValues={{
          name: "",
          email: "",
          // phoneNumber: "",
          // countryCode: "",
          password: "",
          confirmPassword: "",
          ageConsent: true,
          termsOfConsent: true,
          signupMethod: "email",
          referralByCode: referralByCode,
        }}
        validationSchema={createUserSchema}
        onSubmit={(value, { resetForm }) => {
          dispatch(userSignup(value))
          // resetForm()
        }}
      >
        {(formikProps) => (
          <Form>
            <h2 className='text-center font-bold text-[40px] mb-5 mt-0'>Sign Up</h2>
            <Field className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.name && formikProps.errors.name ? 'is-invalid' : ''}`} name="name" type="name" maxLength="50" placeholder="Full Name" />
            <ErrorMessage
              component="div"
              name="name"
              className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
              style={{ display: "block" }}
            />
            <Field className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.email && formikProps.errors.email ? 'is-invalid' : ''}`} name="email" type="email" maxLength="50" placeholder="Email" />
            <ErrorMessage
              component="div"
              name="email"
              className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
              style={{ display: "block" }}
            />
            <PhoneInputComponent formikProps={formikProps} phone={formikProps.values.phoneNumber} className={`h-[55px] w-full ps-3 bg-white border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.phoneNumber && formikProps.errors.phoneNumber ? 'is-invalid' : ''}`} name="phoneNumber" maxLength="50" type="phone" />
            <ErrorMessage
              component="div"
              name="phoneNumber"
              className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
              style={{ display: "block" }}
            />

            <div className="relative">
              <Field
                className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.password && formikProps.errors.password ? 'is-invalid' : ''
                  }`}
                name="password"
                maxLength="50"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
              />
              <span
                className="absolute right-4 top-5 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
              <ErrorMessage
                component="div"
                name="password"
                className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                style={{ display: "block" }}
              />
            </div>

            {/* Confirm Password Field */}
            <div className="relative">
              <Field
                className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.confirmPassword && formikProps.errors.confirmPassword ? 'is-invalid' : ''
                  }`}
                name="confirmPassword"
                maxLength="50"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
              />
              <span
                className="absolute right-4 top-5 cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
              <ErrorMessage
                component="div"
                name="confirmPassword"
                className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                style={{ display: "block" }}
              />
            </div>
            <p className="text-center mt-0 text-[#000000] text-[16px] font-normal">By clicking on Sign up or Social logins, you accept <Link className="text-[#ED9E34] text-[16px] font-semibold" to={'/'}>Terms of Use</Link> and confirm you are <b>above 18 years.</b></p>
            <button type="submit" className="h-[55px] w-full px-3 uppercase bg-[#000000] rounded-lg text-white font-bold text-[20px] border-none hover:!text-white hover:!bg-gradient-to-l hover:from-[#6AD2D4] hover:to-[#ED9E34] cursor-pointer" disabled={!(formikProps.isValid && formikProps.dirty)}>{isLoader ? <PulseLoader color="#FFFFFF" /> : "Sign Up"}</button>
          </Form>
        )}
      </Formik>
      <p className="mb-5 text-center text-[#000000] text-[16px] font-normal">Or sign up using</p>
      <SocialRegister />
      <p className="mb-0 text-center text-[#000000] text-[16px] font-normal">Already have a account? <Link className="text-[#ED9E34] text-[16px] font-semibold" to={'/'}>Sign in</Link></p>
    </div>
  )
}

export default SignUpForm