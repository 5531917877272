export const GAME_RULES = "GAME_RULES";
export const SET_GAME_RULES = "SET_GAME_RULES";
export const APP_SETTING = "APP_SETTING";
export const SET_APP_SETTING = "SET_APP_SETTING";
export const GET_USER_LIST = "GET_USER_LIST";
export const SET_LOADER = "SET_LOADER";
export const PROFILE_LOADER = "PROFILE_LOADER";
export const SET_USER_LIST = "SET_USER_LIST";
export const USER_SIGNIN = "USER_SIGNIN";
export const LEADERBOARD = "LEADERBOARD";
export const SET_LEADERBOARD = "SET_LEADERBOARD";
export const USER_SIGNUP = "USER_SIGNUP";
export const USER_SIGNUP_STATUS = "USER_SIGNUP_STATUS";
export const USER_PROFILE = "USER_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const DELETE_PROFILE_PIC = "DELETE_PROFILE_PIC";
export const SET_UPDATE_PROFILE = "SET_UPDATE_PROFILE";
export const UPLOAD_PROFILE_PIC = "UPLOAD_PROFILE_PIC";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_USER_DATA = "SET_USER_DATA";
export const SOCIAL_SIGNUP = "SOCIAL_SIGNUP";
export const SET_SOCIAL_SIGNUP = "SET_SOCIAL_SIGNUP";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_OTP_VERIFY = "FORGOT_PASSWORD_OTP_VERIFY";
export const SET_ERROR_MSG = "SET_ERROR_MSG";
export const FORGOT_PASSWORD_STEP = "FORGOT_PASSWORD_STEP";
export const SET_FORGOT_PASSWORD = "SET_FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SET_RESET_PASSWORD = "SET_RESET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const EMAIL_VERIFY = "EMAIL_VERIFY";
export const EMAIL_VERIFY_STATUS = "EMAIL_VERIFY_STATUS";
export const EMAIL_VERIFY_OTP = "EMAIL_VERIFY_OTP";
export const SET_EMAIL_VERIFY_OTP = "SET_EMAIL_VERIFY_OTP";
export const SET_EMAIL_VERIFY = "SET_EMAIL_VERIFY";
export const LOGOUT = "LOGOUT";