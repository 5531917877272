import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Layout from '../../components/Layout'
import img from '../../assets/get-app.svg'
import appStore from '../../assets/appstore.svg'
import playStore from '../../assets/googleplay.svg'
import referral from '../../assets/undraw-referral.svg'
import { Card, Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import ReferralCodeCopy from '../../components/ReferralCodeCopy'
import { userProfile } from '../../redux/action/authAction'
import { useDispatch, useSelector } from 'react-redux'
import Leaderboard from './Leaderboard'
import ReferralSystemIcon from '../../assets/referral-system-icon.svg'

const Dashboard = () => {
  const dispatch = useDispatch();
  const [appStoreUrl, setAppStore] = useState();
  const [playStoreUrl, setPlayStore] = useState();
  const auth = useSelector((state) => state?.auth?.userData?.isAuth);
  const appSettingData = useSelector((state) => state?.auth?.appSettingData);
  useEffect(() => {
    document.title = 'Cryptordle - Dashboard';
    if (auth) {
      dispatch(userProfile())
    }
    if (appSettingData) {
      const appStore = appSettingData?.find(item => item.settingKey === "android_url");
      const playStore = appSettingData?.find(item => item.settingKey === "ios_url");
      setAppStore(appStore.settingValue)
      setPlayStore(playStore.settingValue)
    }
  }, [appSettingData, auth, dispatch])
  return (
    <div>
      <Layout>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-[30px] pb-6 px-0">
          <Leaderboard />
          <div className='bg-white p-5 ml-0 mb-4 rounded-lg flex flex-col gap-5'>
            <h2 className="m-0 text-2xl font-bold uppercase gradient-text roboto-condensed">Referral System</h2>
            {/* <div className="comming-soon"> */}
              <Row className="items-center">
                <Col span={14}>
                  <h3 className="w-4/5 text-[18px] font-semibold text-[#6AD2D4] mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                  <p className="w-4/5 text-base text-[#333333]">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </Col>
                <Col span={10}>
                  <img className="w-full md:w-[70%] lg:w-[100%] xl:w-[80%]" src={referral} alt='Referral System Image'></img>
                </Col>
              </Row>
              <div>
                {/* <input className="bg-white text-base text-[#0A142F] font-normal border w-full h-14 py-1 px-5 rounded-[10px]" type='text' placeholder='game.mydomain.com/login'></input> */}
                <ReferralCodeCopy />
              </div>
              <div className="comming-cont">
                <img className="w-[200px] h-[200px]" src={ReferralSystemIcon} alt='Referral System Icon'></img>
                <button className="comming-soon-btn"><span className="roboto-condensed">COMING SOON</span></button>
              </div>
            {/* </div> */}
          </div>
        </div>
        <Card className="p-0 bg-gradient-to-l from-[#6AD2D4] via-[#FCF7A0] to-[#ED9E34]">
          <Row className="items-center mx-auto md:w-[70%] w-full">
            <Col span={12} xs={24} md={24} lg={12} className="hidden md:hidden lg:block">
              <img className="w-full -mb-7" src={img} alt='Get the App and Win Img'></img>
            </Col>
            <Col span={12} xs={24} md={24} lg={12} className='md:ps-10'>
              <h3 className="text-4xl text-center roboto-condensed md:w-[70%] mx-auto">Get the App and Win $50,000 in Crypto</h3>
              <div className="flex md:gap-8 gap-3 justify-center">
                <Link to={appStoreUrl}>
                  <img className="md:w-[180px] w-full img-fluid" src={appStore} alt='Apple Store'></img>
                </Link>
                <Link to={playStoreUrl}>
                  <img className="md:w-[180px] w-full img-fluid" src={playStore} alt='Google Play Store'></img>
                </Link>
              </div>
            </Col>
            <Col span={12} xs={24} md={24} lg={12} className="xs:block md:hidden mt-5">
              <img className="w-[398.99px] -mb-7 img-fluid" src={img} alt='Get the App and Win Img'></img>
            </Col>
          </Row>
        </Card>
      </Layout>
    </div>
  )
}

export default Dashboard