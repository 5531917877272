import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import profileImg from '../../assets/avtar.png'
import { Col, Row } from 'antd'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import PhoneInputComponent from '../../components/phone-input/PhoneInputComponent'
import { useDispatch, useSelector } from 'react-redux';
import ChangePassword from '../../components/modal/ChangePassword'
import UploadProfile from '../../components/UploadProfile'
import { PulseLoader } from 'react-spinners'
import { updateProfile, userProfile } from '../../redux/action/authAction'

function Profile() {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state?.auth?.userData?.isAuth);
    
    const { name, email, phoneNumber, profilePic } = useSelector((state) => state?.auth?.userData || {});
    const isLoader = useSelector((state) => state?.auth?.isLoader);

    const profileSchema = Yup.object({
        name: Yup.string()
            .min(3, 'Name must be 3 characters or long.')
            .max(50, 'Name must be 50 characters or long.')
            .matches(
                /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                'Name should not contain any numeric or special characters.'
            )
            .required('Name is required.'),

        // email: Yup.string()
        //     .email('Please enter a valid email.')
        //     .required('Email is required.'),

        // phoneNumber: Yup.string()
        //     .min(4, 'Phone number must be between 4 to 16 digits.')
        //     .max(16, 'Phone number must be between 4 to 16 digits.'),

        // password: Yup.string()
        //     .matches(passwordRegex, 'Password must be at least 8 characters long and include at least 1 digit, 1 uppercase, 1 lowercase character and 1 special character.')
        //     .required('Password is required.'),

    })
    useEffect(() => {
        document.title = 'Cryptordle - Profile';
        if (auth) {
            dispatch(userProfile())
        }
    }, [auth, dispatch])

    return (
        <div className="custom-input-width">
            <Layout>
                <div className='bg-white p-5 rounded-lg flex flex-col gap-5 font-inter'>
                    <h2 className="m-0 text-2xl font-bold uppercase gradient-text roboto-condensed">Profile</h2>
                    <Row className="items-center justify-center">
                        <Col span={12} xs={24} md={12}>
                            <div>
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        name: name || '',
                                        email: "",
                                        countryCode: "",
                                    }}
                                    validationSchema={profileSchema}
                                    onSubmit={(value, { resetForm }) => {
                                        dispatch(updateProfile(value))
                                        resetForm()
                                    }}
                                >
                                    {(formikProps) => (
                                        <Form className="mb-24">
                                            <UploadProfile profilePic={profilePic} />
                                            <label className="text-base mb-5">Full Name<span className="text-red-400">*</span></label>
                                            <Field className={`mt-1 h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-5 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.name && formikProps.errors.name ? 'is-invalid' : ''}`} name="name" type="text" maxLength="50" placeholder="Name" />
                                            <ErrorMessage
                                                component="div"
                                                name="name"
                                                className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                                style={{ display: "block" }}
                                            />
                                            <label className="text-base mb-5">Email</label>
                                            <Field className={`mt-1 h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-5 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.email && formikProps.errors.email ? 'is-invalid' : ''}`} name="email" type="email" value={email} disabled maxLength="50" placeholder="Email" />
                                            <ErrorMessage
                                                component="div"
                                                name="email"
                                                className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                                style={{ display: "block" }}
                                            />
                                            <label className="text-base mb-5">Phone Number (Optional)</label>
                                            <PhoneInputComponent formikProps={formikProps} phone={formikProps.values.phoneNumber} className={`h-[55px] w-full ps-3 bg-white border border-[#6A7186] border-solid rounded-lg mb-5 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.phoneNumber && formikProps.errors.phoneNumber ? 'is-invalid' : ''}`} name="phoneNumber" value={phoneNumber} type="phone" />
                                            <ErrorMessage
                                                component="div"
                                                name="phoneNumber"
                                                className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                                style={{ display: "block" }}
                                            />

                                            <label className="text-base mb-5">Password</label>
                                            <div className="relative">
                                                <Field
                                                    className={`mt-1 h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-5 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.password && formikProps.errors.password ? 'is-invalid' : ''
                                                        }`}
                                                    name="password"
                                                    disabled
                                                    maxLength="50"
                                                    // type={showPassword ? "text" : "password"}
                                                    placeholder="xxxxxxxx"
                                                />
                                                <ChangePassword />
                                                <ErrorMessage
                                                    component="div"
                                                    name="password"
                                                    className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                                    style={{ display: "block" }}
                                                />
                                            </div>
                                            <button type="submit" className="h-[55px] w-full px-3 uppercase bg-[#000000] rounded-lg text-white font-bold text-[20px] border-none hover:!text-white hover:!bg-gradient-to-l hover:from-[#6AD2D4] hover:to-[#ED9E34] cursor-pointer" disabled={!(formikProps.isValid && formikProps.dirty)}>{isLoader ? <PulseLoader color="#FFFFFF" /> : "Save"}</button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </div>
    )
}

export default Profile