import React, { useEffect } from 'react'
import avatar from '../../assets/avtar.png'
import LeaderboardIcon from '../../assets/leaderboard-icon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { leaderboard } from '../../redux/action/authAction';

function Leaderboard() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.userData);
  const leaderboardData = useSelector((state) => state?.auth?.LeaderboardData);
  console.log("leaderboard0", leaderboardData);

  useEffect(() => {
    document.title = 'Cryptordle - Dashboard';
    if (auth) {
      dispatch(leaderboard())
    }
  }, [auth, dispatch])

  return (
    <div className="bg-white p-5 mr-0 mb-4 rounded-lg flex flex-col gap-5">
      <h2 className="m-0 text-2xl font-bold uppercase gradient-text roboto-condensed">Leaderboard</h2>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Stage</th>
            <th>Attempts</th>
            <th>Rank</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData?.map((user) => (
            <tr key={user?.id} className={`${user.isWinner === true ? "winner" : "" || user.isLoggedInUser === true ? "myrank" : "" }`}>
              <td>{user?.name}</td>
              <td>{user?.currentStage}</td>
              <td>{user?.totalAttempts}</td>
              <td>{user?.ranking}</td>
            </tr>
          ))}
          {/* <tr className="myrank">
            <td>
              <span className="flex items-center gap-1">
                <img className="w-9 rounded-full" src={avatar} alt='User Image' /> Jhon Deo
              </span>
            </td>
            <td>12</td>
            <td>50</td>
            <td>#3</td>
          </tr> */}
        </tbody>
        {/* <div className="comming-cont">
          <img className="w-[200px] h-[200px]" src={LeaderboardIcon} alt='Leaderboard Icon'></img>
          <button className="comming-soon-btn"><span className="roboto-condensed">COMING SOON</span></button>
        </div> */}
      </table>
    </div>
  )
}

export default Leaderboard