import api from "../../../utils/api"

export const signinApi = async (payload) => {
    return await api.post(`/auth/login`, payload)
}

export const signupApi = async (payload) => {
    return await api.post(`/auth/signup`, payload)
}

export const profileApi = async (payload) => {
    return await api.get(`/auth/me`, payload)
}

export const leaderboardApi = async (payload) => {
    return await api.get(`/user/leaderboard`, payload)
}

export const appSettingApi = async (payload) => {
    return await api.get(`/public/app-settings`, payload)
}

export const gameRulesApi = async (payload) => {
    const { game_type, topic } = payload;
    return await api.get(`/rules/${game_type}/${topic}`, payload)
}

export const updateProfileApi = async (payload) => {
    return await api.put(`/user`, payload)
}

export const uploadProfilePicApi = async (file) => {
    const formData = new FormData();
    if (file && file instanceof File) {
        formData.append('profilePic', file);
    }
    return await api.post(`/user/upload-profile-pic`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',  
        },
    });
};


export const deleteProfilePicApi = async (payload) => {
    return await api.delete(`/user/delete-profile-pic`, payload)
}

export const socialSignupApi = async (payload) => {
    return await api.post(`/auth/social-signup`, payload)
}

export const forgotPasswordApi = async (payload) => {
    return await api.post(`/auth/password-reset-otp`, payload)
}

export const forgotPassworOtpVerifydApi = async (payload) => {
    return await api.post(`/auth/verify-password-reset-otp`, payload)
}

export const changePasswordApi = async (payload) => {
    return await api.post(`/user/change-password`, payload)
}

export const resetPasswordApi = async (payload) => {
    return await api.post(`/auth/reset-password`, payload)
}

export const emailVerifyApi = async (payload) => {
    return await api.get(`/auth/send-email-verification-otp`, payload)
}

export const emailVerifyOtpApi = async (payload) => {
    return await api.post(`/auth/verify-email-otp`, payload)
}

export const logOutApi = async () => {
    return await api.post(`/auth/logout`)
}